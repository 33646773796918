import React, { useState, useEffect } from "react";

import { DepositInterface, DepositCodeType } from "types/Deposit";

import axios from "axios";

import { useParams, useLocation, useSearchParams } from "react-router-dom";

import { Icon } from "@iconify/react";

import { DepositStatusBadge } from "components/Deposit/StatusBadge";
import { DepositActionBadge } from "components/Deposit/ActionBadge";
import { Link } from "components/Common/Link";
import { Timer } from "components/Common/Timer";
import { FieldItem } from "components/Common/FieldItem";
import moment from "moment";
import { TetherAddressCardContent } from "components/TetherAddress/CardContent";

import socket from "socket";
import { TetherAddressInteface } from "types/TetherAddress";
import { toast } from "react-toastify";

const DepositDrawer: React.FC = () => {
  const [deposit, setDeposit] = useState<DepositInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const depositId = searchParams.get("depositId");

  useEffect(() => {
    socket.on("deposit_upd", (upd: DepositInterface) => {
      setDeposit((prev) => (prev?._id == upd._id ? upd : prev));
    });

    return () => {
      socket.off("deposit_upd");
    };
  }, []);

  // Hooks
  useEffect(() => {
    if (!depositId) {
      setDeposit(null);
      socket.off("deposit_upd");
      return;
    }

    setLoading(true);
    axios.get(`/deposits/${depositId}`).then(({ data }) => {
      setDeposit(data);
      setLoading(false);
    });
  }, [depositId]);

  const handleClose = () => {
    searchParams.delete("depositId");
    setSearchParams(searchParams);
  };

  const isOpen = !!depositId;

  const isLoaded = !loading && !!deposit;

  return (
    <div className="drawer drawer-end">
      <input
        id="requisite-drawer"
        type="checkbox"
        checked={isOpen}
        className="drawer-toggle"
      />

      <div className="drawer-side">
        <label
          htmlFor="mrequisite-drawe"
          aria-label="close sidebar"
          className="drawer-overlay"
          onClick={handleClose}
        ></label>
        <div className="menu w-full sm:w-500px min-h-full bg-base-200 text-base-content">
          <div className="p-3">
            <div className="flex justify-end">
              <button onClick={handleClose} className="btn btn-circle">
                <Icon fontSize="26" icon="ic:round-close" />
              </button>
            </div>
            {isLoaded ? <Content data={deposit} /> : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ContentProps {
  data: DepositInterface;
}

const Content: React.FC<ContentProps> = ({ data }) => {
  let view;
  let btnsView;

  if (data.action == "topup") {
    view = <TopupView data={data} />;
    btnsView = <TopupBtns className="mt-2" _id={data._id} code={data.code} />;
  } else if (data.action == "withdraw") {
    view = <WithdrawView data={data} />;
    btnsView = (
      <WithdrawBtns className="mt-2" _id={data._id} code={data.code} />
    );
  }

  return (
    <div>
      <div className="mt-3 flex items-center justify-between">
        <DepositActionBadge className="badge-lg" status={data.action} />
        <DepositStatusBadge code={data.code} className="badge-lg" />
      </div>
      <Link
        name={data.account.email}
        href={`/users/${data.account._id}`}
        className="mt-3"
      />
      <div className="flex items-center mt-3">
        <p className="text-sm text-neutral-content mt-2">
          {moment(data.createdAt).format("HH:mm DD.MM.YY")}
        </p>
      </div>
      <div className="divider"></div>

      <div className="mt-4">
        <div className="flex justify-between items-center mb-3">
          <FieldItem name="Сумма" value={`${data.sum} USDT`} />
          <div>
            {data.code == "payment" && data.action == "topup" && (
              <Timer finished={data.expired} />
            )}
          </div>
        </div>
        {view}
        {btnsView}
      </div>
    </div>
  );
};

interface TopupViewProps {
  data: DepositInterface;
  className?: string;
}

const TopupView: React.FC<TopupViewProps> = ({ className, data }) => {
  return (
    <div className={`card bg-neutral shadow-xl ${className}`}>
      <div className="px-4 py-3">
        {data.code == "cancel" && data.comment ? (
          <div role="alert" className="alert alert-error p-2 mb-2">
            <span> Комментарий: {data.comment}</span>
          </div>
        ) : null}
        {data.code == "confirm-payment" && (
          <div role="alert" className="alert alert-info p-2 mb-2">
            <span> Пользователь подтвердил отправление средств.</span>
          </div>
        )}
        <p className="mb-3 font-bold">Клиент переводит с реквизитов:</p>
        <FieldItem name={"Адрес USDT"} value={data.address} />

        {data?.data?.wallet && (
          <>
            <p className="mb-3 font-bold mt-4">
              Реквизиты сервиса для получения:
            </p>
            <FieldItem
              name={data.data.wallet.name}
              value={data.data.wallet.value}
            />
          </>
        )}
      </div>
    </div>
  );
};

interface TopupBtnsProps {
  className?: string;
  _id: string;
  code: DepositCodeType;
}

const TopupBtns: React.FC<TopupBtnsProps> = ({ code, _id, className = "" }) => {
  let [submit, setSubmit] = useState<string>("");
  let [isViewCancel, setIsViewCancel] = useState<boolean>(false);
  let [comment, setComment] = useState<string>("");

  let view;

  const doneHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("done");
    axios
      .put("/deposits", {
        action: "done",
        _id,
      })

      .catch(() => {})
      .finally(() => {
        setSubmit("");
      });
  };

  const restoreHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("restore");

    axios
      .put("/deposits", {
        action: "restore",
        _id,
      })
      .catch(() => {})
      .finally(() => {
        setSubmit("");
      });
  };

  const cancelHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("cancel");
    axios
      .put("/deposits", {
        action: "cancel",
        comment,
        _id,
      })
      .catch(() => {})
      .finally(() => {
        setSubmit("");
      });
  };

  const refundHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("refund");

    axios
      .put("/deposits", {
        action: "refund",
        comment,
        _id,
      })
      .catch(() => {})
      .finally(() => {
        setSubmit("");
      });
  };

  const cancelBtns = (
    <>
      <div className="col-span-12">
        <div className="label">
          <span className="label-text">Комментарий</span>
        </div>
        <input
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Не обязатьельно"
          className="input input-bordered input-md w-full"
        />
      </div>
      <div className="col-span-6">
        <button
          onClick={cancelHandler}
          disabled={submit == "cancel"}
          className="btn btn-outline btn-ghost w-full"
        >
          {submit == "cancel" && (
            <span className="loading loading-spinner"></span>
          )}
          Отклонить
        </button>
      </div>
      <div className="col-span-6">
        <button
          onClick={() => setIsViewCancel(false)}
          className="btn btn-outline btn-error w-full"
        >
          Отменить
        </button>
      </div>
    </>
  );

  if (code == "payment" || code == "confirm-payment") {
    view = (
      <>
        <div className="col-span-6">
          <button
            onClick={doneHandler}
            disabled={submit == "done"}
            className="btn btn-outline btn-primary w-full"
          >
            {submit == "done" && (
              <span className="loading loading-spinner"></span>
            )}
            Средства получены
          </button>
        </div>
        <div className="col-span-6">
          <button
            onClick={() => setIsViewCancel(true)}
            className="btn btn-outline btn-error w-full"
          >
            Отклонить
          </button>
        </div>
      </>
    );
  } else if (code == "done") {
    view = (
      <div className="col-span-6">
        <button
          onClick={refundHandler}
          disabled={submit == "refund"}
          className="btn btn-outline btn-warning w-full"
        >
          {submit == "refund" && (
            <span className="loading loading-spinner"></span>
          )}
          Возврат
        </button>
      </div>
    );
  } else if (code == "cancel") {
    view = (
      <div className="col-span-6">
        <button
          onClick={restoreHandler}
          disabled={submit == "restore"}
          className="btn btn-outline btn-warning w-full"
        >
          {submit == "restore" && (
            <span className="loading loading-spinner"></span>
          )}
          Восстановить
        </button>
      </div>
    );
  }

  if ((code == "payment" || code == "confirm-payment") && isViewCancel) {
    view = cancelBtns;
  }

  return (
    <div className={`${className}`}>
      <div className="grid items-center justify-between grid-cols-12 gap-2 mt-6">
        {view}
      </div>
    </div>
  );
};

const WithdrawView: React.FC<TopupViewProps> = ({ className, data }) => {
  return (
    <div className={`card bg-neutral shadow-xl ${className}`}>
      <div className="px-4 py-3">
        {data.code == "cancel" && data.comment ? (
          <div role="alert" className="alert alert-error p-2 mb-2">
            <span> Комментарий: {data.comment}</span>
          </div>
        ) : null}
        {data.code == "payment" && (
          <div role="alert" className="alert alert-info p-2 mb-2">
            <span> Ожидание подтверждения мерчанта.</span>
          </div>
        )}
        {data.code == "confirm-payment" && (
          <>
            <div role="alert" className="alert alert-info p-2 mb-2">
              <span> Мерчант подтвердил вывод средств.</span>
            </div>
          </>
        )}
        {data.code !== "payment" && (
          <>
            <p className="mb-3 font-bold">Реквизиты клиента:</p>
            <FieldItem name={"Адрес USDT"} value={data.address} />
          </>
        )}
        {data.code == "done" && data.data.wallets?.length && (
          <div>
            <p className="mb-3 font-bold mt-4">
              Реквизиты сервиса для получения:
            </p>
            <WithdrawWalletsTable wallets={data.data.wallets} />
          </div>
        )}
      </div>
    </div>
  );
};

interface WithdrawBtnsProps {
  className?: string;
  _id: string;
  code: DepositCodeType;
}

const WithdrawBtns: React.FC<WithdrawBtnsProps> = ({
  className = "",
  code,
  _id,
}) => {
  let [submit, setSubmit] = useState<string>("");
  let [comment, setComment] = useState<string>("");
  let [actionView, setActionView] = useState<string>("");

  const cancelHandler = () => {
    changeApiHandler("cancel");
  };

  const confirmHandler = () => {
    changeApiHandler("confirm");
  };

  const changeApiHandler = (action: string) => {
    setSubmit(action);
    axios
      .put("/deposits", {
        _id,
        action,
        comment: comment || "Администратор отклонил заявку",
      })

      .then(() => {
        setActionView("");
      })
      .finally(() => {
        setSubmit("");
      });
  };

  let view;

  const cancelBtns = (
    <>
      <div className="col-span-12">
        <div className="label">
          <span className="label-text">Комментарий</span>
        </div>
        <input
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Не обязательно"
          className="input input-bordered input-md w-full"
        />
      </div>
      <div className="col-span-6">
        <button
          onClick={cancelHandler}
          disabled={submit == "cancel"}
          className="btn btn-outline btn-ghost w-full"
        >
          {submit == "cancel" && (
            <span className="loading loading-spinner"></span>
          )}
          Отклонить
        </button>
      </div>
      <div className="col-span-6">
        <button
          onClick={() => setActionView("")}
          className="btn btn-outline btn-error w-full"
        >
          Отменить
        </button>
      </div>
    </>
  );

  if (code == "confirm-payment") {
    view = (
      <>
        <div className="col-span-6">
          <button
            onClick={() => {
              setActionView("select-requisite");
            }}
            className="btn btn-outline btn-primary w-full"
          >
            Выбрать реквизит
          </button>
        </div>
        <div className="col-span-6">
          <button
            onClick={() => setActionView("cancel")}
            className="btn btn-outline btn-error w-full"
          >
            Отклонить
          </button>
        </div>
      </>
    );
  } else if (code == "done") {
    view = (
      <div className="col-span-6">
        <button
          onClick={cancelHandler}
          disabled={submit == "cancel"}
          className="btn btn-outline btn-warning w-full"
        >
          {submit == "cancel" && (
            <span className="loading loading-spinner"></span>
          )}
          Возврат
        </button>
      </div>
    );
  } else if (code == "cancel") {
    view = (
      <div className="col-span-6">
        <button
          onClick={confirmHandler}
          disabled={submit == "confirm"}
          className="btn btn-outline btn-warning w-full"
        >
          {submit == "confirm" && (
            <span className="loading loading-spinner"></span>
          )}
          Восстановить
        </button>
      </div>
    );
  }
  if (actionView == "select-requisite") {
    view = (
      <WithdrawSelectAddress
        _id={_id}
        closeHandler={() => {
          setActionView("");
        }}
      />
    );
  } else if (actionView == "cancel") {
    view = cancelBtns;
  }

  return (
    <div className={`${className}`}>
      <div className="grid items-center justify-between grid-cols-12 gap-2 mt-6">
        {view}
      </div>
    </div>
  );
};

interface WithdrawSelectAddressProps {
  closeHandler: Function;
  _id: string;
}

const WithdrawSelectAddress: React.FC<WithdrawSelectAddressProps> = ({
  closeHandler,
  _id,
}) => {
  const [addresses, setAddresses] = useState<TetherAddressInteface[]>([]);
  const [selectedList, setSelectedList] = useState<
    {
      _id: string;
      sum: string;
      name: string;
    }[]
  >([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  useEffect(() => {
    axios.get("/tether-address").then(({ data }) => {
      setAddresses(data);
    });
  }, []);

  const addItemSelectedList = (_id: string, name: string) => {
    if (selectedList.some((i) => i._id == _id)) {
      return;
    }
    setSelectedList((prev) => [{ _id, sum: "0", name }, ...prev]);
  };

  const removeItemSelectedList = (_id: string) => {
    setSelectedList((prev) => prev.filter((i) => i._id !== _id));
  };

  const changeItemSum = (_id: string, sum: string) => {
    setSelectedList((prev) =>
      prev.map((i) => {
        if (i._id == _id) {
          return {
            _id,
            sum,
            name: i.name,
          };
        }
        return i;
      })
    );
  };

  const changeHandler = () => {
    setIsSubmit(true);

    axios
      .put("/deposits", {
        _id,
        action: "done",
        wallets: selectedList,
      })
      .then(() => {
        toast.success("Выполнено!");
        closeHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const existSelectedAndSum: boolean =
    !!selectedList.length && selectedList.some((s) => +s.sum > 0);

  return (
    <div className="col-span-12">
      <div className="grid items-center justify-between grid-cols-12 gap-2 mb-4">
        <div className="col-span-6">
          <button
            onClick={changeHandler}
            disabled={isSubmit || !existSelectedAndSum}
            className="btn btn-outline btn-ghost w-full"
          >
            {isSubmit && <span className="loading loading-spinner"></span>}
            Выполнить
          </button>
        </div>
        <div className="col-span-6">
          <button
            onClick={() => {
              closeHandler();
            }}
            className="btn btn-outline btn-error w-full"
          >
            Отменить
          </button>
        </div>
      </div>
      <div>
        {addresses.map((a) => {
          let selectedItem = selectedList.find((s) => s._id == a._id);
          let isSelected: boolean = !!selectedItem;
          let addedClassName = isSelected ? `border-2 border-primary` : "";
          return (
            <div
              onClick={() => {
                if (!isSelected) {
                  addItemSelectedList(a._id, a.name);
                }
              }}
              className={`card cursor-pointer bg-neutral shadow-xl mb-2 border-accent ${addedClassName}`}
            >
              <div className="px-4 py-3">
                <div className="flex justify-between">
                  <div>
                    <FieldItem name="Название" value={a.name} />
                  </div>
                  <div>
                    <FieldItem
                      name="Баланс"
                      value={a.balance + ""}
                      postfix="USDT"
                    />
                  </div>
                </div>
                {isSelected && (
                  <div className="mt-2">
                    <div className="flex justify-between items-center">
                      <input
                        type="number"
                        value={selectedItem?.sum}
                        onChange={(e) => changeItemSum(a._id, e.target.value)}
                        placeholder="Сумма"
                        className="input input-bordered input-sm w-full"
                      />
                      <button
                        onClick={() => {
                          removeItemSelectedList(a._id);
                        }}
                        className="btn btn-square btn-sm ml-3"
                      >
                        <Icon fontSize="26" icon="ic:round-close" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const WithdrawWalletsTable: React.FC<{
  wallets: [
    {
      _id: string;
      sum: string;
      name: string;
    }
  ];
}> = ({ wallets = [] }) => {
  return (
    <div>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Название</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {wallets.map((w) => {
              return (
                <tr key={w?._id}>
                  <td>{w?.name}</td>
                  <td>{w?.sum} USDT</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DepositDrawer;
