import config from "config/API";

function getIconFullUrl(imagename: string): string {
  return config.API_ICONS + "/" + imagename;
}

function getImageFullUrl(imagename: string): string {
  return config.API_IMAGES + "/" + imagename;
}

export { getIconFullUrl, getImageFullUrl };
