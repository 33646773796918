import axios from "axios";
import { useState, useEffect } from "react";

import { AdminInteface } from "types/Admin";

import { Icon } from "@iconify/react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import InputMask, { Props } from "react-input-mask";

import { useMask } from "@react-input/mask";

import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

function Admins() {
  const [admins, setAdmins] = useState<AdminInteface[]>([]);
  const [openDroawer, setOpenDrawer] = useState(false);
  const [adminData, setAdminData] = useState<AdminInteface | null>(null);

  useEffect(() => {
    axios.get("/admins").then(({ data }) => {
      setAdmins(data);
    });
  }, []);

  const closeHandlerDrawer = () => {
    setOpenDrawer(false);
    setAdminData(null);
  };

  const openDrawerHandler = (data: AdminInteface | null) => {
    setAdminData(data);
    setOpenDrawer(true);
  };

  const addNewItem = (item: AdminInteface) => {
    setAdmins((prev) => [item, ...prev]);
  };

  const deleteItem = (id: string) => {
    setAdmins((prev) => prev.filter((p) => p._id !== id));
  };

  const updateItem = (upd: AdminInteface) => {
    setAdmins((prev) => prev.map((p) => (p._id == upd._id ? upd : p)));
  };

  return (
    <div>
      <div className="mt-4 flex items-center justify-between">
        <h4 className="text-xl">Администраторы</h4>
        <button
          onClick={() => {
            openDrawerHandler(null);
          }}
          className="btn btn-active btn-primary"
        >
          <Icon icon="subway:admin-1" />
          Создать
        </button>
      </div>
      <AdminDrawer
        data={adminData}
        show={openDroawer}
        handleClose={closeHandlerDrawer}
        addItemHanlder={addNewItem}
        deleteItemHandler={deleteItem}
        updateItemHandler={updateItem}
      />

      <div className="mt-4">
        {admins.map((a) => (
          <AdminCard
            clickHandler={() => {
              openDrawerHandler(a);
            }}
            key={a._id}
            admin={a}
            className="mb-2"
          />
        ))}
      </div>
    </div>
  );
}

interface AdminCardProps {
  admin: AdminInteface;
  className?: string;
  clickHandler: Function;
}

const AdminCard: React.FC<AdminCardProps> = ({
  className = "",
  admin,
  clickHandler,
}) => {
  return (
    <div
      onClick={() => {
        clickHandler();
      }}
      className={`cursor-pointer card bg-base-300 shadow-xl ${className}`}
    >
      <div className="px-4 py-3">
        <div className="grid items-center grid-cols-12 gap-2">
          <div className="lg:col-span-4 col-span-6">
            <p className="text-lg font-bold">{admin.login}</p>
          </div>
          <div className="lg:col-span-3 col-span-6">
            {admin.phone ? `+${admin.phone}` : "Телефон остутствует"}
          </div>
        </div>
      </div>
    </div>
  );
};

interface AdminDrawerProps {
  show: boolean;
  handleClose: Function;
  data: AdminInteface | null;
  addItemHanlder: Function;
  deleteItemHandler: Function;
  updateItemHandler: Function;
}

const AdminDrawer: React.FC<AdminDrawerProps> = ({
  show,
  handleClose,
  data,
  addItemHanlder,
  deleteItemHandler,
  updateItemHandler,
}) => {
  const [submit, setSubmit] = useState<string>("");
  const [login, setLogin] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const closeHandler = () => {
    handleClose();
  };

  const deleteHandler = () => {};

  // if (!data) return null;

  return (
    <div className="drawer drawer-end absolute z-10">
      <input
        id="requisite-drawer"
        type="checkbox"
        checked={show}
        className="drawer-toggle"
      />

      <div className="drawer-side">
        <label
          htmlFor="mrequisite-drawe"
          aria-label="close sidebar"
          className="drawer-overlay"
          onClick={closeHandler}
        ></label>
        <div className="menu w-full sm:w-500px min-h-full bg-base-200 text-base-content">
          <div className="p-3">
            <div className="flex justify-end">
              <button onClick={closeHandler} className="btn btn-circle">
                <Icon fontSize="26" icon="ic:round-close" />
              </button>
            </div>
            <div>
              {data ? (
                <ChangeAdminDrawerContent
                  closeHandler={closeHandler}
                  data={data}
                  updateItemHandler={updateItemHandler}
                  deleteItemHandler={deleteItemHandler}
                />
              ) : (
                <CreateAdminDrawerContent
                  closeHandler={closeHandler}
                  addItemHanlder={addItemHanlder}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const schemaCreateAdmin = yup.object({
  login: yup.string().required("Введите Логин").min(6, "Мин. 6 символов"),
  password: yup
    .string()
    .required("Введите пароль")
    .min(6, "Мин. 6 символов")
    .max(30, "Макс. 30 символов"),
  phone: yup
    .string()
    .transform((value) => value.replace(/\D/g, "")) // Убираем все нецифровые символы
    .length(11, "Длина мин. 11 символов"),
});

const CreateAdminDrawerContent = ({
  addItemHanlder,
  closeHandler,
}: {
  addItemHanlder: Function;
  closeHandler: Function;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      login: "",
      password: "",
      phone: "",
    },
    resolver: yupResolver(schemaCreateAdmin),
  });

  const onSubmit = handleSubmit((d) => {
    setIsLoading(true);
    axios
      .post("/admins", d)
      .then(({ data }) => {
        toast.success("Администратор добавлен!");
        addItemHanlder(data);
        closeHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  });
  return (
    <div>
      <form className="mt-8 space-y-6" onSubmit={onSubmit}>
        <div className="">
          <div className="mb-4">
            <Controller
              name={"login"}
              control={control}
              render={({ field: { onChange, value } }) => {
                let errorMessage = errors.login?.message;

                return (
                  <div>
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <input
                        type="text"
                        {...register("login")}
                        id="login"
                        value={value}
                        onChange={onChange}
                        className="grow"
                        placeholder="Логин"
                      />
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name={"phone"}
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                let errorMessage = errors.phone?.message;

                return (
                  <div>
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <InputMask
                        mask="+9 (999) 999-99-99"
                        value={value}
                        onChange={onChange}
                      >
                        <input className="grow" placeholder="Телефон" />
                      </InputMask>
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name={"password"}
              control={control}
              render={({ field: { onChange, value } }) => {
                let errorMessage = errors.password?.message;

                return (
                  <div>
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <input
                        type="text"
                        {...register("password")}
                        id="password"
                        value={value}
                        onChange={onChange}
                        className="grow"
                        placeholder="Пароль"
                      />
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div>
          <button
            onClick={onSubmit}
            type="submit"
            className="btn btn-primary btn-block"
          >
            {isLoading && <span className="loading loading-spinner" />}
            Создать
          </button>
        </div>
      </form>
    </div>
  );
};

const schemaChangeAdmin = yup.object({
  login: yup.string().min(6, "Мин. 6 символов"),
  password: yup.string().test(
    "is-valid-password", // Название теста
    "Мин. 6 символов, макс. 30 символов", // Сообщение об ошибке
    function (value) {
      // Если поле пустое, валидация проходит
      if (!value) return true;

      // Если строка есть, проверяем на минимальное и максимальное количество символов
      return value.length >= 6 && value.length <= 30;
    }
  ),
  phone: yup
    .string()
    .transform((value) => value.replace(/\D/g, "")) // Убираем все нецифровые символы
    .length(11, "Длина мин. 11 символов"),
});

const ChangeAdminDrawerContent = ({
  updateItemHandler,
  closeHandler,
  data,
  deleteItemHandler,
}: {
  updateItemHandler: Function;
  closeHandler: Function;
  data: AdminInteface;
  deleteItemHandler: Function;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      login: data.login,
      password: "",
      phone: data.phone,
    },
    resolver: yupResolver(schemaChangeAdmin),
  });

  const deleteHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    axios
      .delete("/admins", {
        params: { id: data._id },
      })
      .then(() => {
        toast.success("Администратор удален!");
        deleteItemHandler(data._id);
        closeHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = handleSubmit((d) => {
    setIsLoading(true);
    axios
      .put("/admins", {
        ...d,
        _id: data._id,
      })
      .then(({ data }) => {
        toast.success("Администратор изменен!");
        updateItemHandler(data);
        closeHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  });
  return (
    <div>
      <form className="mt-8 space-y-6" onSubmit={onSubmit}>
        <div className="">
          <div className="mb-4">
            <Controller
              name={"login"}
              control={control}
              render={({ field: { onChange, value } }) => {
                let errorMessage = errors.login?.message;

                return (
                  <div>
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <input
                        type="text"
                        {...register("login")}
                        id="login"
                        value={value}
                        onChange={onChange}
                        className="grow"
                        placeholder="Логин"
                      />
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name={"phone"}
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                let errorMessage = errors.phone?.message;

                return (
                  <div>
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <InputMask
                        mask="+9 (999) 999-99-99"
                        value={value}
                        onChange={onChange}
                      >
                        <input className="grow" placeholder="Телефон" />
                      </InputMask>
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name={"password"}
              control={control}
              render={({ field: { onChange, value } }) => {
                let errorMessage = errors.password?.message;

                return (
                  <div>
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <input
                        type="text"
                        {...register("password")}
                        id="password"
                        value={value}
                        onChange={onChange}
                        className="grow"
                        placeholder="Пароль"
                      />
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div>
          <button
            onClick={onSubmit}
            type="submit"
            className="btn btn-primary btn-block"
          >
            {isLoading && <span className="loading loading-spinner" />}
            Сохранить
          </button>
        </div>
        <button
          onClick={deleteHandler}
          disabled={isRemoveLoading}
          className="btn btn-outline btn-error mt-5 w-full"
        >
          {isRemoveLoading && <span className="loading loading-spinner"></span>}
          Удалить
        </button>
      </form>
    </div>
  );
};

export default Admins;
