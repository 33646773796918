import { useEffect, useState } from "react";

import { Icon } from "@iconify/react";

import { RequisiteInterface } from "types/Requisite";

import { RequisiteSelect } from "modules/Requisite/RequisiteSelect";

import axios from "axios";
import { toast } from "react-toastify";

interface IProps {
    isOpen: boolean;
    handleClose: Function;
}

interface IFieldItem {
    id: string;
    name: string;
    value: string;
}

const CreateOrder: React.FC<IProps> = ({ isOpen, handleClose }) => {
    const [requisites, setRequisites] = useState<RequisiteInterface[]>([]);
    const [requisiteSelectedId, setRequisiteSelectedId] = useState<string>('');
    const [sum, setSum] = useState<string>('');
    const [orderId, setOrderId] = useState<string>('')
    const [fields, setFields] = useState<IFieldItem[]>([])
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpen) {
            setRequisites([]);
            setRequisiteSelectedId('');
            setSum('');
            setOrderId('');
            setFields([]);
            return;
        };
        axios.get("/requisites/verifyed").then(({ data: list }) => {
            setRequisites(list);
            if (!requisiteSelectedId) {
                setRequisiteSelectedId(list[0]?._id)
            }
        });

    }, [isOpen])

    const submitHandler = () => {
        setIsSubmit(true)
        axios.post('/orders/create', {
            requisiteId: requisiteSelectedId,
            sum: +sum,
            fields,
            orderId
        })
            .then(() => {
                toast.success("Заявка создана!")
                handleClose()
            })
            .finally(() => {
                setIsSubmit(false);
            })
        // 
    }

    const closeHandler = () => {

        handleClose();
    };


    return <div>

        <div className="drawer drawer-end">
            <input
                id="requisite-drawer"
                type="checkbox"
                checked={isOpen}
                className="drawer-toggle"
            />

            <div className="drawer-side">
                <label
                    htmlFor="mrequisite-drawe"
                    aria-label="close sidebar"
                    className="drawer-overlay"
                    onClick={closeHandler}
                ></label>
                <div className="menu w-full sm:w-500px min-h-full bg-base-200 text-base-content">
                    <div className="p-3">
                        <div className="flex justify-between items-center">
                            <p className="text-xl font-bold">Создание заявки</p>
                            <button onClick={closeHandler} className="btn btn-circle">
                                <Icon fontSize="26" icon="ic:round-close" />
                            </button>
                        </div>
                        <RequisiteSelect
                            list={requisites}
                            setSelected={setRequisiteSelectedId}
                            selected={requisiteSelectedId}
                        />
                        <div>
                            <div className="label">
                                <span className="label-text">Сумма заявки</span>
                            </div>
                            <input
                                type="number"
                                value={sum}
                                onChange={(e) => setSum(e.target.value)}
                                placeholder="RUB"
                                className="input input-bordered input-md w-full "
                            />
                        </div>
                        <div>
                            <div className="label">
                                <span className="label-text">ID завявки обмена</span>
                            </div>
                            <input
                                type="number"
                                value={orderId}
                                onChange={(e) => setOrderId(e.target.value)}
                                placeholder=""
                                className="input input-bordered input-md w-full "
                            />
                        </div>
                        <FieldList
                            fields={fields}
                            setFields={setFields}
                        />
                        <button
                            onClick={submitHandler}
                            disabled={isSubmit}
                            type="submit"
                            className="btn btn-primary btn-md mt-8 btn-block"
                        >
                            {isSubmit && <span className="loading loading-spinner" />}
                            Создать
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

interface IFieldListProps {
    fields: IFieldItem[];
    setFields: Function;
}

const FieldList: React.FC<IFieldListProps> = ({ fields, setFields }) => {
    return <div>
        <p className="text-lg mt-2 font-bold"> Поля</p>
        {
            fields.map(f => {
                return <div key={f.id} className="flex mb-5 items-end">
                    <div className="grid grid-cols-2 gap-4 flex-1">
                        <div>
                            <div className="label">
                                <span className="label-text">Название</span>
                            </div>
                            <input
                                type="text"
                                value={f.name}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setFields((prev: IFieldItem[]) => {
                                        return prev.map((pf: IFieldItem) => {
                                            if (pf.id == f.id) {
                                                return Object.assign({}, f, { name: value })
                                            } else {
                                                return pf;
                                            }
                                        })
                                    })
                                }}
                                placeholder="Название"
                                className="input input-bordered input-md w-full max-w-xs"
                            />
                        </div>
                        <div>
                            <div className="label">
                                <span className="label-text">Значение</span>
                            </div>
                            <input
                                type="text"
                                value={f.value}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setFields((prev: IFieldItem[]) => {
                                        return prev.map((pf: IFieldItem) => {
                                            if (pf.id == f.id) {
                                                return Object.assign({}, f, { value: value })
                                            } else {
                                                return pf;
                                            }
                                        })
                                    })
                                }}
                                placeholder="Значение"
                                className="input input-bordered input-md w-full max-w-xs"
                            />
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            setFields((prev: IFieldItem[]) => {
                                return prev.filter(fitem => fitem.id !== f.id)
                            })
                        }}
                        className="btn btn-circle"
                    >
                        <Icon fontSize="26" icon="ic:round-close" />
                    </button>

                </div>
            })
        }
        <button
            onClick={() => {
                setFields((prev: any) => [...prev, {
                    name: '',
                    value: '',
                    id: String(Date.now())
                }])
            }}
            type="submit"
            className="btn btn-primary btn-sm btn-md mt-3 btn-block"
        >

            Добавить
        </button>
    </div>
}

export default CreateOrder;