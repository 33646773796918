import React, { useState, useEffect } from "react";

import { Icon } from "@iconify/react";

import axios from "axios";

import { OrderAuctionInterface } from "types/Order";
import AuctionCard from "./AuctionCard";
import AuctionDrawer from "./AuctionDrawer";

import socket from "socket";

const OrderAuctions: React.FC = () => {
  const [auctions, setAuctions] = useState<OrderAuctionInterface[]>([]);
  const [showIsDrawer, setShowIsDrawer] = useState<boolean>(false);
  const [drawerOrder, setDrawerOrder] = useState<OrderAuctionInterface | null>(
    null
  );

  useEffect(() => {
    axios.get("/orders/auction/active").then(({ data }) => {
      setAuctions(data);
    });
    socket.on("auction_add", (added: OrderAuctionInterface) => {
      setAuctions((prev) => [added, ...prev]);
    });

    socket.on("auction_upd", (upd: OrderAuctionInterface) => {
      setAuctions((prev) => prev.map((p) => (p._id == upd._id ? upd : p)));
    });
    socket.on("auction_del", (id: string) => {
      setAuctions((prev) => prev.filter((p) => p._id !== id));
    });

    return () => {
      socket.off("auction_add");
      socket.off("auction_upd");
      socket.off("auction_del");
    };
    //
  }, []);

  let activeAuctions = auctions.filter((a) =>
    ["wait", "cancel", "expired"].includes(a.status)
  );

  const openDrawerHandler = (order: OrderAuctionInterface) => {
    setDrawerOrder(order);
    setShowIsDrawer(true);
  };

  let emptyActiveAuctions = !!activeAuctions.length;

  return (
    <div className="mb-4">
      {emptyActiveAuctions && (
        <>
          <div className="flex justify-between items-center">
            <h4 className="text-2xl">Аукционы</h4>
          </div>
          <div className="mt-3">
            <div className="mt-4 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {activeAuctions.map((a) => (
                <AuctionCard
                  onClick={() => openDrawerHandler(a)}
                  className="col-span-1"
                  data={a}
                  key={a._id}
                />
              ))}
            </div>
          </div>
        </>
      )}
      <AuctionDrawer
        show={showIsDrawer}
        order={drawerOrder}
        handleClose={() => {
          setShowIsDrawer(false);
        }}
      />
    </div>
  );
};

export default OrderAuctions;
