import React, { createContext, useState, useEffect, ReactNode } from "react";
// import { createContext, useContext, useEffect, useMemo, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// // import { useLocalStorage } from "./useLocalStorage";
import axios from "axios";
// // Store
// import GlobalDataStore from "store/globalData";
import socket, { auth, logout as socketLogout } from "socket";

// interface ThemeContextType {
//   // theme: string;
//   // setTheme: (theme: string) => void;
// }

// Store
import MainStore from "store/main";

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextType {
  isLoading: boolean;
  isLoaded: boolean;
  isAuth: boolean;
  login: Function;
  logout: Function;
}

interface AdminDataInterface {
  _id: string;
  email: string;
}


const initContext = {
  isLoading: false,
  isLoaded: false,
  isAuth: false,
  login: () => {},
  logout: () => {},
  account: null,
};

const AuthContext = createContext<AuthContextType>(initContext);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [account, setAccount] = useState<null | AdminDataInterface>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadedAccount, setIsLoadedAccount] = useState<boolean>(false);
  const [isLoadedGlobalData, setIsLoadedGlobalData] = useState<boolean>(false);

  //  Calc
  const isAuth = !!account;
  const isLoaded = isLoadedAccount && isLoadedGlobalData;

  //

  const fetchAllData = () => {
    setIsLoadedGlobalData(false);

    let currenciesPromise = axios.get("/currencies");
    let globalDataPromise = axios.get("/global-data");

    Promise.all([currenciesPromise, globalDataPromise]).then((values) => {
      let currencies = values[0]?.data;
      let globalData = values[1]?.data;
      MainStore.setCurrencies(currencies);
      MainStore.setGlobalData(globalData);
      setIsLoadedGlobalData(true);
    });
  };

  const fetchAccountData = () => {
    let token = localStorage.getItem("access_token");

    if (!token) {
      setIsLoadedAccount(true);
      setIsLoadedGlobalData(true);
      return;
    }

    setIsLoadedAccount(false);

    axios
      .get("/profile")
      .then(({ data }) => {
        setAccount(data);
        auth();
      })
      .catch(() => {
        localStorage.removeItem("access_token");
      })
      .finally(() => {
        setIsLoadedAccount(true);
      });
  };

  useEffect(() => {
    fetchAccountData();
    // 
    // socket.on("global-data_upd", (upd) => {
    //   GlobalDataStore.setData(upd);
    // });
    // return () => {
    //   socket.off("global-data_upd");
    // };
  }, []);

  useEffect(() => {
    if (isAuth) {
      fetchAllData();
    }
  }, [isAuth]);

  // Handlers
  const login = async (token: string) => {
    localStorage.setItem("access_token", token);
    fetchAccountData();
  };

  const logout = () => {
    setAccount(null);
    // socketLogout();
    localStorage.removeItem("access_token");
  };

  const value = {
    account,
    isLoading,
    isAuth,
    login,
    logout,
    isLoaded: isLoaded,
    // balance,
    // setAccountEnabled,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
