import { Routes, Route, Navigate } from "react-router-dom";

// Pages
import Login from "pages/Login";

// Auth Orders
import Panel from "pages/Panel";
import Orders from "pages/Orders";
import Users from "pages/Users";
import Requisites from "pages/Requisites";
import Deposits from "pages/Deposits";
import Tariffs from "pages/Tariffs";
import User from "pages/User";
import Logs from "pages/Logs";
import Settings from "pages/Settings";


// Layouts
import PublicLayout from "layouts/PublicLayout";
import AuthLayout from "layouts/AuthLayout";

import { useAuth } from "hooks/useAuth";

function MainRouter() {
  const { isAuth, isLoaded } = useAuth();

  // useEffect(() => {
  //   if (!isAuth) return;
  // }, [isAuth]);

  if (!isLoaded) {
    return <span className="loading loading-spinner loading-lg"></span>;
  }

  return <div>{isAuth ? <PrivateRoutes /> : <PublicRoutes />}</div>;
}

function PublicRoutes() {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="*" element={<Navigate to="/login" replace={true} />} />
    </Routes>
  );
}

function PrivateRoutes() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="/dashboard" element={<Panel />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<User />} />
        <Route path="/requisites" element={<Requisites />} />
        <Route path="/deposits" element={<Deposits />} />
        <Route path="/tariffs" element={<Tariffs />} />
        <Route path="/logs" element={<Logs />} />
        <Route path="/settings" element={<Settings />} />
      </Route>
      <Route path="*" element={<Navigate to="/orders" replace={true} />} />
    </Routes>
  );
}

export default MainRouter;
