import React, { useState, useEffect } from "react";

import { Link } from "components/Common/Link";
import { CurrencyInfo } from "components/Currency/CurrencyInfo";
import { IsCheckedBadge } from "components/Order/IsCheckedBadge";
import { OrderActionBadge } from "components/Order/ActionBadge";

import { OrderReceiveStatus, OrderSendStatusType } from "types/Order";

import API from "config/API";

import { getIconFullUrl } from "utils/link";

import moment from "moment";

import { OrderStatusBadge } from "components/Order/StatusBadge";

interface OrderDrawerInfoProps {
  _id: string;
  createdAt: string;
  currency: {
    name: string;
    logo: string;
  };
  account: {
    email: string;
    _id: string;
  };
  isChecked: boolean;
  action: "receive" | "send";
  orderId: string;
  status: OrderReceiveStatus | OrderSendStatusType;
}

const OrderDrawerInfo: React.FC<OrderDrawerInfoProps> = ({
  createdAt,
  currency,
  account,
  isChecked,
  action,
  orderId,
  status,
  _id,
}) => {
  const orderLink = `${API.EXCHANGER}/orders?orderId=${orderId}`;

  const showCheckStatus =
    (action == "receive" && status == "cancel") ||
    (action == "send" && status == "done");

  // status == "done" || status == "cancel";

  return (
    <div>
      <div className="mb-3 flex justify-between">
        <p className="text-xl font-bold">{_id}</p>
        <OrderStatusBadge className="badge-lg" status={status} />
      </div>
      <div className="mb-3 flex justify-between">
        <OrderActionBadge status={action} className="badge-lg" />
      </div>

      {showCheckStatus && (
        <div className="mb-3">
          <IsCheckedBadge className="badge-lg" isChecked={isChecked} />
        </div>
      )}

      <CurrencyInfo
        name={currency.name}
        logo={getIconFullUrl(currency.logo)}
        size="small"
        className="mb-3"
      />
      <Link
        name={`Заявка №${orderId}`}
        href={orderLink}
        className="mb-2 w-max"
        isNewtab
      />
      <Link
        name={account.email}
        href={`/users/${account._id}`}
        className="mb-2"
      />
      <p className="text-sm text-neutral-content mt-2">
        {moment(createdAt).format("HH:mm DD.MM.YY")}
      </p>
    </div>
  );
};

export default OrderDrawerInfo;
