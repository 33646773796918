import { OrderAuctionInterface } from "types/Order";

import { CurrencyInfo } from "components/Currency/CurrencyInfo";
import { Timer } from "components/Common/Timer";

import { OrderAuctionStatusBadge } from "components/OrderAuction/StatusBadge";

import { getIconFullUrl } from "utils/link";

import moment from "moment";

interface TypesProps {
  className?: string;
  data: OrderAuctionInterface;
  onClick: Function;
}

export default function AuctionCard(props: TypesProps) {
  let { data, className = "", onClick } = props;

  return (
    <div
      onClick={() => onClick()}
      className={`cursor-pointer card bg-base-300 shadow-xl ${className}`}
    >
      <div className="px-4 py-3 ">
        <div className="flex justify-between">
          <CurrencyInfo
            size="small"
            name={data.currency.name}
            logo={getIconFullUrl(data.currency.logo)}
          />
          <OrderAuctionStatusBadge status={data.status} />
        </div>

        <p className=" mt-2">
          <b>{data.sum}</b> <span className="text-neutral-content">RUB</span>
        </p>
        <div className="flex justify-between items-center mt-1">
          <div className="text-sm  text-neutral-content">
            <Timer finished={data.expired} />
          </div>
          <p className="text-sm  text-neutral-content">
            {moment(data.createdAt).format("HH:mm DD.MM.YY")}
          </p>
        </div>
      </div>
    </div>
  );
}
