import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";

import socket from "socket";

// types
import { RequisiteInterface } from "types/Requisite";

// modules
import RequisiteCard from "modules/Requisite/RequisiteCard";

const Requisites: React.FC = () => {
  const [requisites, setRequisites] = useState<RequisiteInterface[]>([]);

  // uec callback

  const updateRequisiteHandler = useCallback((upd: RequisiteInterface) => {
    setRequisites((prev) => prev.map((r) => (r._id === upd._id ? upd : r)));
  }, []);
  const addedRequisiteHandler = useCallback((add: RequisiteInterface) => {
    setRequisites((prev) => [add, ...prev]);
  }, []);

  useEffect(() => {
    axios.get("/requisites").then(({ data }) => {
      setRequisites(data);
    });

    socket.on("requisite_upd", updateRequisiteHandler);
    socket.on("requisite_add", addedRequisiteHandler);

    return () => {
      socket.off("requisite_upd", updateRequisiteHandler);
      socket.off("requisite_add", addedRequisiteHandler);
    };
  }, []);

  return (
    <div className="  px-4 mt-4">
      <h4 className="text-2xl">Реквизиты</h4>
      <div className="mt-4">
        <div className="grid items-center justify-between grid-cols-12 gap-2">
          {requisites.map((r) => {
            return (
              <div key={r._id} className="lg:col-span-4 col-span-12">
                <RequisiteCard data={r} className="mb-3" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  // requisites
};

export default Requisites;
