const isDevMode = process.env.NODE_ENV === "development";

const SERVER = isDevMode ? "http://localhost:3001" : "https://api.pay2pay.org";

const config = {
  API: `${SERVER}/admin`,
  API_IMAGES: `${SERVER}/images`,
  API_ICONS: `${SERVER}/icons`,
  SERVER,
  EXCHANGER: `https://panewfexrcpeefqeqeqfqweqw.xyz`,
};

export default config;
