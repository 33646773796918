import React from "react";

import { useNavigate } from "react-router-dom";

import { DepositInterface } from "types/Deposit";

import { DepositActionBadge } from "components/Deposit/ActionBadge";
import { DepositStatusBadge } from "components/Deposit/StatusBadge";

import moment from "moment";

interface DepositCardProps {
  className?: string;
  data: DepositInterface;
}

const DepositCard: React.FC<DepositCardProps> = ({ data, className = "" }) => {
  const navigate = useNavigate();

  const toClientHandler = (event: React.MouseEvent<HTMLParagraphElement>) => {
    event.stopPropagation();
    navigate(`/users/${data.account._id}`);
  };

  const openDepositDrawer = () => {
    navigate(`?depositId=${data._id}`);
  };

  return (
    <div
      onClick={openDepositDrawer}
      className={`cursor-pointer card bg-base-300 shadow-xl ${className}`}
    >
      <div className="px-4 py-3 ">
        <div className="mb-4 flex justify-between items-center">
          <DepositActionBadge className="badge-lg" status={data.action} />
          <DepositStatusBadge className="badge-lg " code={data.code} />
        </div>

        <p
          onClick={toClientHandler}
          className="text-base font-bold link link-primary mb-2"
        >
          {data.account.email}
        </p>
        <p className="text-base font-bold mb-2">{data.sum} USDT</p>

        <div className="flex justify-end">
          <p className="text-sm text-neutral-content">
            {moment(data.createdAt).format("HH:mm DD.MM.YY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export { DepositCard };
