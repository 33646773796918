import React, { useEffect, useState } from "react";

import { OrderAuctionInterface } from "types/Order";
import { CurrencyInfo } from "components/Currency/CurrencyInfo";
import { Timer } from "components/Common/Timer";
import moment from "moment";
import { OrderAuctionStatusBadge } from "components/OrderAuction/StatusBadge";
import { FieldItem } from "components/Common/FieldItem";
import socket from "socket";
import { Link } from "components/Common/Link";
import { Icon } from "@iconify/react";

import API from "config/API";
import axios from "axios";
import { toast } from "react-toastify";

import { getIconFullUrl } from "utils/link";

interface AuctionDrawerProps {
  order: OrderAuctionInterface | null;
  show: boolean;
  handleClose: Function;
}

const AuctionDrawer: React.FC<AuctionDrawerProps> = ({
  show,
  handleClose,
  order,
}) => {
  const [auction, setAuction] = useState<OrderAuctionInterface | null>(order);

  const orderId = order?._id;

  useEffect(() => {
    setAuction(order);
  }, [orderId]);

  useEffect(() => {
    socket.on("auction_upd", (upd: OrderAuctionInterface) => {
      setAuction((prev) => (prev?._id == upd._id ? upd : prev));
    });
    return () => {
      socket.off("auction_upd");
    };
  }, []);

  const closeHandler = () => {
    handleClose();
  };

  const isLoaded = show && !!auction;

  return (
    <div className="drawer drawer-end absolute z-10">
      <input
        id="requisite-drawer"
        type="checkbox"
        checked={show}
        className="drawer-toggle"
      />

      <div className="drawer-side">
        <label
          htmlFor="mrequisite-drawe"
          aria-label="close sidebar"
          className="drawer-overlay"
          onClick={closeHandler}
        ></label>
        <div className="menu w-full sm:w-500px min-h-full bg-base-200 text-base-content">
          <div className="p-3">
            <div className="flex justify-end">
              <button onClick={closeHandler} className="btn btn-circle">
                <Icon fontSize="26" icon="ic:round-close" />
              </button>
            </div>
            {isLoaded ? (
              <Content closeHandler={closeHandler} data={auction} />
            ) : (
              "loading"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ContentProps {
  data: OrderAuctionInterface;
  closeHandler: Function;
}

const Content: React.FC<ContentProps> = ({ data, closeHandler }) => {
  const orderLink = `${API.EXCHANGER}/orders?orderId=${data.orderId}`;
  const [submit, setSubmit] = useState<"delete" | "recover" | null>(null);

  let btns = null;

  const deleteHandler = () => {
    if (submit) return;
    setSubmit("delete");
    axios
      .put("/order-auction", {
        action: "delete",
        orderId: data.orderId,
      })
      .then(() => {
        toast.success("Аукцион удален");
        closeHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setSubmit(null);
      });
  };

  const recoverHandler = () => {
    if (submit) return;
    setSubmit("recover");
    axios
      .put("/order-auction", {
        action: "restore",
        orderId: data.orderId,
      })
      .then(() => {
        toast.success("Аукцион восстановлен");
      })
      .catch((e) => {})
      .finally(() => {
        setSubmit(null);
      });
  };

  let deleteBtn = (
    <div className="col-span-6">
      <button
        onClick={deleteHandler}
        disabled={submit == "delete"}
        className="btn btn-outline btn-error w-full"
      >
        {submit == "delete" && (
          <span className="loading loading-spinner"></span>
        )}
        Удалить
      </button>
    </div>
  );
  let recoverBtn = (
    <div className="col-span-6">
      <button
        onClick={recoverHandler}
        disabled={submit == "recover"}
        className="btn btn-outline btn-warning w-full"
      >
        {submit == "recover" && (
          <span className="loading loading-spinner"></span>
        )}
        Восстановить
      </button>
    </div>
  );

  if (data.status == "wait") {
    btns = deleteBtn;
  } else if (data.status == "expired") {
    btns = (
      <>
        {recoverBtn}
        {deleteBtn}
      </>
    );
  }

  return (
    <div>
      <OrderAuctionStatusBadge className="badge-lg" status={data.status} />
      <CurrencyInfo
        size="medium"
        className="mt-3"
        name={data.currency.name}
        logo={getIconFullUrl(data.currency.logo)}
      />
      <Link
        name={`Заявка №${data.orderId}`}
        href={orderLink}
        className="mt-3"
        isNewtab
      />
      {data.status == "wait" && (
        <div className="mt-3">
          <Timer finished={data.expired} />
        </div>
      )}

      <div className="flex justify-end items-center mt-3">
        <p className="text-sm text-neutral-content mt-2">
          {moment(data.createdAt).format("HH:mm DD.MM.YY")}
        </p>
      </div>
      <div className="divider"></div>
      <div className="mt-4">
        <div className="flex justify-between items-center mb-3">
          <FieldItem name="Сумма" value={`${data.sum}`} postfix="RUB" />
        </div>
      </div>
      <div className=" card bg-neutral shadow-xl">
        <div className="px-4 py-3">
          <p className="font-bold mt-2 mb-1">Куда переводят:</p>
          {data.fields.map((f) => {
            return <FieldItem key={f._id} name={f.name} value={f.value} />;
          })}
        </div>
      </div>
      <div className="grid items-center justify-between grid-cols-12 gap-2 mt-6">
        {btns}
      </div>
    </div>
  );
};

export default AuctionDrawer;
