import { useState } from "react";
import { Document, Page } from "react-pdf";

interface Props {
  // data: DepositInterface;
  className?: string;
  width?: number;
  url: string;
}

const PdfViewer: React.FC<Props> = ({ className, url, width = 400 }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={width}
          pageNumber={pageNumber}
        />
      </Document>

      {numPages > 1 && (
        <div className="flex items-center justify-center mt-3">
          <div className="join">
            {Array.from({ length: numPages }, (_, index) => {
              let itemPage = index + 1;
              return (
                <button
                  onClick={() => setPageNumber(itemPage)}
                  className={`join-item btn btn-sm ${
                    itemPage == pageNumber ? "btn-active" : ""
                  }`}
                >
                  {itemPage}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export { PdfViewer };
