import React, { useEffect, useState } from "react";
import axios from "axios";

const LogsPage: React.FC = () => {
  const [logs, setLogs] = useState("");
  const [logSearch, setLogSearch] = useState("");

  useEffect(() => {
    axios.get("/system/logs").then(({ data }) => {
      setLogs(data);
    });
  }, []);

  const tags = logSearch.split(" ").filter(t => t).map((t) => t.trim());

  let filtredLogs = filterLogs(logs, tags)

  return (
    <div className="px-4 mt-4">
      <h4 className="text-2xl">Системные логи</h4>

      <div>
        <input
          value={logSearch}
          onChange={(e) => setLogSearch(e.target.value)}
          type="text"
          placeholder="Тэги через пробел"
          className="input bg-base-300 mt-4 input-bordered input-md w-full"
        />
      </div>
      <div className="mt-8 py-3 px-5 card bg-base-300 shadow-xl">
        <div className="overflow-y-auto max-h-screen">
          <pre>{filtredLogs}</pre>
        </div>
      </div>
      <div className="mt-8"></div>
    </div>
  );
};

function filterLogs(logs: string, tags: string[]) {
  if(!tags.length){
    return logs
  }

  let raws = logs.split("\n");

  let rawsResult: string[] = [];

  raws.forEach((row: string) => {
    let allTagsInclude = false;
    tags.forEach((tag) => {
      allTagsInclude = row.toLowerCase().includes(tag.toLowerCase());
    });

    if (allTagsInclude) {
      rawsResult.push(row);
    }
  });

  return rawsResult.join("\n");
}

export default LogsPage;
