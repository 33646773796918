import React from "react";

import moment from "moment";

import { useNavigate } from "react-router-dom";

//types
import { RequisiteInterface } from "types/Requisite";
// components
import { RequisiteStatusBadge } from "components/Requisite/StatusBadge";
import { RequisiteStatusEnabled } from "components/Requisite/StatusEnabled";

import { CurrencyInfo } from "components/Currency/CurrencyInfo";

import { getIconFullUrl } from "utils/link";

interface RequisiteCardProps {
  data: RequisiteInterface;
  className?: string;
}

const RequisiteCard: React.FC<RequisiteCardProps> = ({
  className = "",
  data,
}) => {
  let field = data.fields.find((f) => f.isUnique);

  const navigate = useNavigate();

  const toClientHandler = (event: React.MouseEvent<HTMLParagraphElement>) => {
    event.stopPropagation();
    navigate(`/users/${data.account._id}`);
  };

  const openRequisiteDrawer = () => {
    navigate(`?requisiteId=${data._id}`);
  };

  return (
    <div
      onClick={openRequisiteDrawer}
      className={`cursor-pointer card bg-base-300 shadow-xl ${className}`}
    >
      <div className="px-4 py-3 ">
        <div className="mb-4 flex justify-between items-center">
          <RequisiteStatusEnabled
            enabled={data.enabled}
            className="badge-lg"
            size="medium"
          />
          <RequisiteStatusBadge className="badge-lg" status={data.status} />
        </div>
        <CurrencyInfo
          name={data.currency.name}
          logo={getIconFullUrl(data.currency.logo)}
          size="small"
          className="mb-2"
        />
        <p
          onClick={toClientHandler}
          className="text-base mb-2 w-max font-bold link link-primary"
        >
          {data.account.email}
        </p>
        <p className="mb-2">{field?.value}</p>
        <p className="text-sm text-neutral-content">
          {moment(data.createdAt).format("HH:mm DD.MM.YY")}
        </p>
        {/* <div className="grid items-center justify-between grid-cols-12 gap-2">
          <div className="lg:col-span-3 col-span-6 flex items-center">
            
            
          </div>
          <div className="lg:col-span-3 col-span-6">
            
          </div>
          <div className="lg:col-span-3 col-span-6">
           
          </div>
          <div className="lg:col-span-3 col-span-6">
            
          </div>
        </div>
        <div className="flex items-center justify-end">
         
        </div> */}
      </div>
    </div>
  );
};

export default RequisiteCard;
