import React, { useState } from "react";

import { Icon } from "@iconify/react";

// Validate
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

// Notifi
import { toast } from "react-toastify";

// Hooks
import { useAuth } from "hooks/useAuth";

const schema = yup.object({
  email: yup.string().required("Введите Логин"),
  password: yup
    .string()
    .required("Введите пароль")
    .min(6, "Мин. 6 символов")
    .max(30, "Макс. 30 символов"),
  showCode: yup.boolean(),
  code: yup.string().when("showCode", (showCode, schema) => {
    if (showCode[0]) {
      // Если есть строка, валидируем по min и max
      return schema.required("Введите код!");
    }
    // Если строки нет, просто не валидируем (делаем поле не обязательным)
    return schema;
  }),
});

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
      code: "",
      showCode: false,
    },
    resolver: yupResolver(schema),
  });

  const { login } = useAuth();

  const onSubmit = handleSubmit((d) => {
    let queryObj: {
      login: string;
      password: string;
      code?: string;
    } = {
      login: d.email,
      password: d.password,
    };

    if (d.showCode) {
      queryObj.code = d.code;
    }

    //
    setIsLoading(true);
    axios
      .post("/login", queryObj)
      .then(({ data }) => {
        let isConfirmCode = data?.status === "code-confirm";
        if (isConfirmCode) {
          setValue("showCode", true);
        } else {
          login(data);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  });

  let { showCode } = getValues();

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md p-8 space-y-8 rounded-lg card card-compact w-96 bg-neutral shadow-xl">
        <h2 className="text-center text-2xl font-bold">Вход</h2>
        <form className="mt-8" onSubmit={onSubmit}>
          <div className="">
            <Controller
              name={"email"}
              control={control}
              render={({ field: { onChange, value } }) => {
                let errorMessage = errors.email?.message;

                return (
                  <div className="mb-4 ">
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <Icon icon="material-symbols:login" />
                      <input
                        type="email"
                        {...register("email")}
                        id="email"
                        value={value}
                        onChange={onChange}
                        className="grow"
                        placeholder="Логин"
                      />
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />

            <Controller
              name={"password"}
              control={control}
              render={({ field: { onChange, value } }) => {
                let errorMessage = errors.password?.message;

                return (
                  <div className="mb-4">
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <Icon icon="mdi:password-outline" />
                      <input
                        type="password"
                        {...register("password")}
                        id="password"
                        value={value}
                        onChange={onChange}
                        className="grow"
                        placeholder="Пароль"
                      />
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />

            {showCode && (
              <Controller
                name={"code"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  let errorMessage = errors.code?.message;

                  return (
                    <div className="mb-4 ">
                      <label
                        className={`input input-bordered flex items-center gap-2 ${
                          errorMessage && "input-error"
                        }`}
                      >
                        <Icon icon="ph:key-bold" />
                        <input
                          type="code"
                          {...register("code")}
                          id="code"
                          value={value}
                          onChange={onChange}
                          className="grow"
                          placeholder="Код"
                        />
                      </label>
                      {Boolean(errorMessage) && (
                        <span className="text-red-500">{errorMessage}</span>
                      )}
                    </div>
                  );
                }}
              />
            )}
          </div>

          <div>
            <button
              onClick={onSubmit}
              type="submit"
              className="btn btn-primary btn-block"
            >
              {isLoading && <span className="loading loading-spinner" />}
              Войти
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
