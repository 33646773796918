import Admins from "./Admins";

const Settings = () => {
  return (
    <div className="  px-4 mt-4">
      <h4 className="text-2xl">Настройки</h4>
      <Admins/>
    </div>
  );
};

export default Settings;
