import React, { useEffect, useState } from "react";

import { Icon } from "@iconify/react";

import { TetherAddressInteface } from "types/TetherAddress";


import axios from "axios";
import { toast } from "react-toastify";
import { FieldItem } from "components/Common/FieldItem";

interface WalletDrawerProps {
  isOpen: boolean;
  handleClose: Function;
}

const WalletDrawer: React.FC<WalletDrawerProps> = ({ isOpen, handleClose }) => {
  const [addresses, setAddresses] = useState<TetherAddressInteface[]>([]);

  useEffect(() => {
    if (isOpen) {
      axios.get("/tether-address").then(({ data }) => {
        setAddresses(data);
      });
    }
  }, [isOpen]);

  const closeHandler = () => {
    setAddresses([]);
    handleClose();
  };

  const updateItem = (upd: TetherAddressInteface) => {
    setAddresses((prev) => prev.map((a) => (a._id == upd._id ? upd : a)));
  };

  return (
    <div className="drawer drawer-end">
      <input
        id="requisite-drawer"
        type="checkbox"
        checked={isOpen}
        className="drawer-toggle"
      />

      <div className="drawer-side">
        <label
          htmlFor="mrequisite-drawe"
          aria-label="close sidebar"
          className="drawer-overlay"
          onClick={closeHandler}
        ></label>
        <div className="menu w-full sm:w-500px min-h-full bg-base-200 text-base-content">
          <div className="p-3">
            <div className="flex justify-between items-center">
              <p className="text-xl font-bold">Кошельки</p>
              <button onClick={closeHandler} className="btn btn-circle">
                <Icon fontSize="26" icon="ic:round-close" />
              </button>
            </div>
            <p>Адрес должен быть Tether в сети TRON (trc-20).</p>
            <AddNewAddress
              addItemHandler={(add: TetherAddressInteface) => {
                setAddresses((prev) => [add, ...prev]);
              }}
              className="mt-4"
            />
            <div className="mt-5">
              {addresses.map((a) => (
                <CardAddress
                  changeItem={updateItem}
                  className="mb-3"
                  key={a._id}
                  data={a}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// card cardcontent

interface CardAddressProps {
  className?: string;
  data: TetherAddressInteface;
  changeItem: Function;
}

const CardAddress: React.FC<CardAddressProps> = ({
  className = "",
  data,
  changeItem,
}) => {
  const [isChange, setIsChange] = useState<boolean>(false);
  const [name, setName] = useState<string>(data.name);
  const [address, setAddress] = useState<string>(data.address);
  const [balance, setBalance] = useState<string>(data.balance + "");

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!isChange) return;
    setName(data.name);
    setAddress(data.address);
    setBalance(data.balance + "");
  }, [isChange]);

  let render;

  const cancelHandler = () => {
    setIsChange(false);
  };

  const saveHandler = () => {
    setIsSaving(true);
    axios
      .put("/tether-address", {
        _id: data._id,
        name,
        address,
        balance,
      })
      .then(({ data }) => {
        toast.success("Кошелек изменен");
        changeItem(data);
        cancelHandler();
      })
      .catch(() => {})
      .finally(() => {
        setIsSaving(false);
      });
  };

  const isFieldChange =
    name !== data.name || address !== data.address || +balance !== data.balance;

  if (isChange) {
    render = (
      <div>
        <div>
          <div className="label">
            <span className="label-text">Название</span>
          </div>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Название"
            className="input input-bordered input-md w-full"
          />
        </div>
        <div className="">
          <div className="label">
            <span className="label-text">Адрес</span>
          </div>
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            type="text"
            placeholder="TRC-20"
            className="input input-bordered input-md w-full"
          />
        </div>
        <div className="">
          <div className="label">
            <span className="label-text">Баланс</span>
          </div>
          <input
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
            type="number"
            placeholder="Баланс USDT"
            className="input input-bordered input-md w-full"
          />
        </div>
        <div className="grid items-center grid-cols-12 gap-2 mt-4">
          <div className="col-span-6">
            <button
              disabled={!isFieldChange}
              className="btn  btn-primary w-full "
              onClick={saveHandler}
            >
              {isSaving && <span className="loading loading-spinner"></span>}
              Сохранить
            </button>
          </div>
          <div className="col-span-6">
            <button onClick={cancelHandler} className="btn btn-error w-full">
              Отменить
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    render = (
      <div>
        <div className="mb-2">
          <div className="flex justify-between">
            <FieldItem name="Название" value={data.name} />
            <button
              onClick={() => setIsChange(true)}
              className="btn btn-square btn-sm"
            >
              <Icon icon="fluent:edit-12-regular" />
            </button>
          </div>
          <div>
            <FieldItem name="Адрес" value={data.address} />
          </div>
          <div>
            <FieldItem name="Баланс" value={`${data.balance}`} postfix="USDT" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`card bg-neutral shadow-xl ${className}`}>
      <div className="px-4 py-3">{render}</div>
    </div>
  );
};

interface AddNewAddressProps {
  className?: string;
  addItemHandler: Function;
}

const AddNewAddress: React.FC<AddNewAddressProps> = ({
  className = "",
  addItemHandler,
}) => {
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [isSaving, setSaving] = useState<boolean>(false);

  const cancelHandler = () => {
    setName("");
    setAddress("");
    setIsAdd(false);
  };

  const saveEnabled = name && address;

  const saveHandler = () => {
    setSaving(true);
    axios
      .post("/tether-address", {
        name,
        address,
      })
      .then(({ data }) => {
        addItemHandler(data);
        toast.success("Кошелек добавлен");
        cancelHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setSaving(false);
      });
  };

  if (!isAdd) {
    return (
      <div
        onClick={() => {
          setIsAdd(true);
        }}
        className={` ${className}`}
      >
        <button className="btn btn-primary w-full size-">Добавить</button>
      </div>
    );
  }

  return (
    <div>
      <div className={`${className}`}>
        <div className="label">
          <span className="label-text">Название</span>
        </div>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Название"
          className="input input-bordered input-md w-full"
        />
      </div>
      <div className="">
        <div className="label">
          <span className="label-text">Адрес</span>
        </div>
        <input
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          type="text"
          placeholder="TRC-20"
          className="input input-bordered input-md w-full"
        />
      </div>
      <div className="grid items-center grid-cols-12 gap-2 mt-4">
        <div className="col-span-6">
          <button
            disabled={!saveEnabled}
            className="btn  btn-primary w-full "
            onClick={saveHandler}
          >
            {isSaving && <span className="loading loading-spinner"></span>}
            Сохранить
          </button>
        </div>
        <div className="col-span-6">
          <button onClick={cancelHandler} className="btn btn-error w-full">
            Отменить
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletDrawer;
