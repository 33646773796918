import React, { useState, useEffect } from "react";
import { OrderInterface } from "types/Order";
import moment from "moment";

import { CurrencyInfo } from "components/Currency/CurrencyInfo";
import { OrderActionBadge } from "components/Order/ActionBadge";
import { useNavigate } from "react-router-dom";
import { OrderStatusBadge } from "components/Order/StatusBadge";

import { getIconFullUrl } from "utils/link";

interface OrderCardProps {
  className?: string;
  data: OrderInterface;
}

const OrderCard: React.FC<OrderCardProps> = ({ className = "", data }) => {
  let statusAction: "receive" | "send";

  const navigate = useNavigate();

  if (data.isReceive) {
    statusAction = "receive";
  } else if (data.isSend) {
    statusAction = "send";
  } else {
    statusAction = "receive";
  }

  const toClientHandler = (event: React.MouseEvent<HTMLParagraphElement>) => {
    event.stopPropagation();
    navigate(`/users/${data.account._id}`);
  };

  const openOrderDrawer = () => {
    navigate(`?orderId=${data._id}`);
  };

  return (
    <div
      onClick={openOrderDrawer}
      className={`cursor-pointer card bg-base-300 shadow-xl ${className}`}
    >
      <div className="px-4 py-3 ">
        <div className="mb-2 flex justify-between items-center">
          <p className="text-xl font-bold">{data._id}</p>
          <OrderStatusBadge className="badge-lg" status={data.status} />
        </div>
        <div className="mb-4 flex justify-between items-center">
          <OrderActionBadge className="badge-lg" status={statusAction} />
        </div>
        <CurrencyInfo
          className="mb-2"
          size="small"
          name={data.currency.name}
          logo={getIconFullUrl(data.currency.logo)}
        />
        <p
          onClick={toClientHandler}
          className="text-base font-bold link link-primary mb-2 w-max"
        >
          {data.account.email}
        </p>
        <p className="text-base font-bold mb-2">{data.sum} RUB</p>
        <p className="text-sm text-neutral-content">
          {moment(data.createdAt).format("HH:mm DD.MM.YY")}
        </p>
      </div>
    </div>
  );
};

export { OrderCard };
