import React, { useState, useEffect } from "react";

import { OrderSendInterface, OrderSendStatusType } from "types/Order";
import { FieldItem } from "components/Common/FieldItem";
import { Timer } from "components/Common/Timer";
import axios from "axios";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";

import { PdfViewer } from "components/Viewer/PdfViewer";

import config from "config/API";
import { ImageViewer } from "components/Viewer/ImageViewer";

interface OrderSendContent {
  order: OrderSendInterface;
  className?: string;
}
const SendContent: React.FC<OrderSendContent> = ({ className = "", order }) => {
  let comment = order.comment;
  let commentClassName = "";
  let isShowComment = order.status == "done" || order.status == "cancel";

  if (order.status == "done") {
    commentClassName = "alert-info";
  } else if (order.status == "cancel") {
    commentClassName = "alert-error";
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <FieldItem name="Сумма" value={`${order.sum}`} postfix="RUB" />
        <div>
          {order.status == "wait" && <Timer finished={order.expired} />}
        </div>
      </div>
      {order.receipt && <Receipt value={order.receipt} className="mb-4" />}

      <div className=" card bg-neutral shadow-xl">
        <div className="px-4 py-3">
          <p className="mb-2 mt-2">
            Прибыль партнера: <b>{order.profitSum}</b> RUB (
            <b>{order.profitPercent}</b>%)
          </p>
          {isShowComment && (
            <div role="alert" className={`alert ${commentClassName} p-2`}>
              <span>
                Комментарий: <b>{comment}</b>
              </span>
            </div>
          )}
          <div className="divider"></div>
          <p className="font-bold mt-2 mb-1">Куда переводит:</p>
          {order.fields.map((f) => {
            return <FieldItem key={f._id} name={f.name} value={f.value} />;
          })}
        </div>
      </div>
      <ChangeBtns order={order} />
    </div>
  );
};

interface ChangeBtnsProps {
  order: OrderSendInterface;
  className?: string;
}

type SubmitType = "cancel" | "done" | "wait" | "checked" | null;

const ChangeBtns: React.FC<ChangeBtnsProps> = ({ order, className = "" }) => {
  let [submit, setSubmit] = useState<SubmitType>(null);
  let [isCancelView, setCancelView] = useState<boolean>(false);
  let [comment, setComment] = useState<string>("");

  let { status, isChecked } = order;
  let view = null;

  const changeStatusHandler = (newStatus: SubmitType, comment = "") => {
    setSubmit(newStatus);
    axios
      .put("/order-send", {
        newStatus,
        id: order._id,
        comment,
      })
      .then(() => {
        toast.success("Статус изменен");
        setCancelView(false);
      })
      .catch((e) => {})
      .finally(() => {
        setSubmit(null);
      });
  };

  const checkedHandler = () => {
    setSubmit("checked");
    axios
      .put("/order-send/checked", {
        id: order._id,
      })
      .then(() => {
        toast.success("Заявка проверена! Баланс мерчанта пополнен.");
      })
      .catch((e) => {})
      .finally(() => {
        setSubmit(null);
      });
  };

  const recoverHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler(
      "wait",
      "Администратор восстановил заявку на перевод средств."
    );
  };
  const doneHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler("done", "Администратор подтвердил перевод средств.");
  };

  const cancelHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler(
      "cancel",
      comment || "Администратор отменил перевод средств."
    );
  };

  const cancelBtn = (
    <button
      onClick={() => setCancelView(true)}
      className="btn btn-outline btn-error w-full"
    >
      Отклонить
    </button>
  );

  if (["wait", "selected"].includes(status)) {
    view = (
      <>
        <div className="col-span-6">
          <button
            onClick={doneHandler}
            disabled={submit == "done"}
            className="btn btn-outline btn-primary w-full"
          >
            {submit == "done" && (
              <span className="loading loading-spinner"></span>
            )}
            Получил
          </button>
        </div>

        <div className="col-span-6">{cancelBtn}</div>
      </>
    );
  } else if (status == "done") {
    view = (
      <>
        <div className="col-span-6">{cancelBtn}</div>
        {!isChecked && (
          <div className="col-span-6">
            <button
              onClick={checkedHandler}
              disabled={submit == "checked"}
              className="btn btn-outline btn-success w-full"
            >
              {submit == "checked" && (
                <span className="loading loading-spinner"></span>
              )}
              Проверено
            </button>
          </div>
        )}
      </>
    );
  } else if (status == "cancel") {
    view = (
      <>
        <div className="col-span-6">
          <button
            onClick={recoverHandler}
            disabled={submit == "wait"}
            className="btn btn-outline btn-primary w-full"
          >
            {submit == "wait" && (
              <span className="loading loading-spinner"></span>
            )}
            Восстановить
          </button>
        </div>
      </>
    );
  }

  const cancelView = (
    <>
      <div className="col-span-12">
        <div className="label">
          <span className="label-text">Комментарий</span>
        </div>
        <input
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Не обязательно"
          className="input input-bordered input-md w-full"
        />
      </div>
      <div className="col-span-6">
        <button
          onClick={cancelHandler}
          disabled={submit == "cancel"}
          className="btn btn-outline btn-ghost w-full"
        >
          {submit == "cancel" && (
            <span className="loading loading-spinner"></span>
          )}
          Отклонить
        </button>
      </div>
      <div className="col-span-6">
        <button
          onClick={() => setCancelView(false)}
          className="btn btn-outline btn-error w-full"
        >
          Отменить
        </button>
      </div>
    </>
  );

  if (isCancelView) {
    view = cancelView;
  }

  return (
    <div className="grid items-center justify-between grid-cols-12 gap-2 mt-6">
      {view}
    </div>
  );
};

interface ReceiptProps {
  value: string;
  className?: string;
}

const Receipt: React.FC<ReceiptProps> = ({ value, className = "" }) => {
  let [show, setShow] = useState<boolean>(false);

  const isPdf = isPdfUrlFile(value);

  const SERVER_URL = config.SERVER;
  const FILE_URL = `${SERVER_URL}/images/${value}`;

  return (
    <div className={className}>
      <div
        className={`bg-neutral collapse  ${
          show ? "collapse-open" : "collapse-close"
        } `}
      >
        <div
          onClick={() => setShow((prev) => !prev)}
          className="px-4 py-2 flex justify-between items-center"
        >
          <p>Чек о переводе:</p>
          <Icon fontSize="24px" icon="iconamoon:arrow-down-2-bold" />
        </div>
        <div className="collapse-content">
          {isPdf ? <PdfViewer url={FILE_URL} /> : <ImageViewer url={FILE_URL}/>}
        </div>
      </div>
    </div>
  );

};

function isPdfUrlFile(url: string) {
  return getExtensionFromUrl(url) === "pdf";
}

const getExtensionFromUrl = (url: string) => {
  const urlParts = url.split(".");
  const extension = urlParts[urlParts.length - 1].toLowerCase();
  return extension;
};

export default SendContent;
