import React, { useState, useEffect, useCallback } from "react";

import { useSearchParams } from "react-router-dom";

import {
  OrderInterface,
  OrderReceiveInterface,
  OrderSendInterface,
} from "types/Order";

import axios from "axios";

import { Icon } from "@iconify/react";

import OrderDrawerInfo from "./Info";
import ReceiveContent from "./ReceiveContent";
import SendContent from "./SendContent";

import socket from "socket";

const OrderDrawer: React.FC = () => {
  const [orderReceive, setOrderReceive] =
    useState<OrderReceiveInterface | null>(null);
  const [orderSend, setOrderSend] = useState<OrderSendInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const orderReceiveUpdateHandler = useCallback(
    (upd: OrderReceiveInterface) => {
      setOrderReceive((prev) => (prev?._id == upd._id ? upd : prev));
    },
    []
  );

  const orderSendUpdateHandler = useCallback((upd: OrderSendInterface) => {
    setOrderSend((prev) => (prev?._id == upd._id ? upd : prev));
  }, []);

  useEffect(() => {
    if (!orderId) {
      clearOrder();
      return;
    }

    setLoading(true);
    axios.get(`/orders/${orderId}`).then(({ data }) => {
      if (data.isReceive) {
        setOrderReceive(data);
      } else if (data.isSend) {
        setOrderSend(data);
      }
      setLoading(false);
    });
  }, [orderId]);

  const clearOrder = () => {
    setOrderReceive(null);
    setOrderSend(null);
  };

  useEffect(() => {
    socket.on("order-receive_upd", orderReceiveUpdateHandler);
    socket.on("order-send_upd", orderSendUpdateHandler);

    return () => {
      socket.off("order-receive_upd", orderReceiveUpdateHandler);
      socket.off("order-send_upd", orderSendUpdateHandler);
    };
  }, []);

  const handleClose = () => {
    searchParams.delete("orderId");
    setSearchParams(searchParams);
  };

  const isOpen = !!orderId;

  const existOrder = !!orderReceive || !!orderSend;

  const isLoaded = !loading && existOrder;

  return (
    <div className="drawer drawer-end">
      <input
        id="requisite-drawer"
        type="checkbox"
        checked={isOpen}
        className="drawer-toggle"
      />

      <div className="drawer-side">
        <label
          htmlFor="mrequisite-drawe"
          aria-label="close sidebar"
          className="drawer-overlay"
          onClick={handleClose}
        ></label>
        <div className="menu w-full sm:w-500px min-h-full bg-base-200 text-base-content">
          <div className="p-3">
            <div className="flex justify-end">
              <button onClick={handleClose} className="btn btn-circle">
                <Icon fontSize="26" icon="ic:round-close" />
              </button>
            </div>
            {isLoaded && orderReceive ? (
              <>
                <OrderDrawerInfo
                  action="receive"
                  createdAt={orderReceive.createdAt}
                  currency={orderReceive.currency}
                  account={orderReceive.account}
                  isChecked={orderReceive.isChecked}
                  orderId={orderReceive.orderId}
                  status={orderReceive.status}
                  _id={orderReceive._id}
                />
                <div className="divider"></div>
                <ReceiveContent order={orderReceive} />
              </>
            ) : null}
            {isLoaded && orderSend ? (
              <>
                <OrderDrawerInfo
                  action="send"
                  createdAt={orderSend.createdAt}
                  currency={orderSend.currency}
                  account={orderSend.account}
                  isChecked={orderSend.isChecked}
                  orderId={orderSend.orderId}
                  status={orderSend.status}
                  _id={orderSend._id}
                />
                <div className="divider"></div>
                <SendContent order={orderSend} />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDrawer;
