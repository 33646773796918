import { Icon } from '@iconify/react';
import React, { useState } from 'react'

import { RequisiteInterface } from 'types/Requisite'

import { getIconFullUrl } from "utils/link";

import { CurrencyInfo } from "components/Currency/CurrencyInfo";


interface IProps {
    list: RequisiteInterface[];
    selected: string;
    setSelected: Function
}

const RequisiteSelect: React.FC<IProps> = ({
    list,
    selected,
    setSelected
}) => {
    return <div>

        <label className="form-control max-w-full">
            <div className="label">
                <span className="label-text">Реквизит</span>
            </div>
            <select value={selected} onChange={e => setSelected(e.target.value)} className="select select-bordered">
                {
                    list.map(r => {
                        return <option className='flex flex-col'>
                            {r.currency.name} | {r.fields.find(f => f.isUnique)?.value}| {r.account.email}

                        </option>
                    })
                }
            </select>

        </label>
    </div>
}

export { RequisiteSelect }