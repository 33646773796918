import React from "react";

import { DepositActionType } from "types/Deposit";

interface RequisiteStatusBadgeProps {
  status: DepositActionType;
  className?: string;
}

const DepositActionBadge: React.FC<RequisiteStatusBadgeProps> = ({
  className = "",
  status,
}) => {
  let name = getName(status);
  let addedClassName = getBadgeClassName(status);

  return <div className={`badge ${addedClassName} ${className}`}>{name}</div>;
};

function getBadgeClassName(status: DepositActionType) {
  switch (status) {
    case "topup":
      return "badge-primary";
    case "withdraw":
      return "badge-secondary";
    default:
      return "";
  }
}

function getName(status: DepositActionType) {
  switch (status) {
    case "topup":
      return "Пополнение";
    case "withdraw":
      return "Вывод";
    default:
      return "Неизвестно";
  }
}

export { DepositActionBadge };
