
interface Props {
  className?: string;
  url: string;
}

const ImageViewer: React.FC<Props> = ({ className = "", url }) => {
  return (
    <div className={className}>
      <img
        src={url}
        style={{
          width: "100%",
          borderRadius: 4,
        }}
        alt="Чек"
      />
    </div>
  );
};

export { ImageViewer };
