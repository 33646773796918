import React from "react";

import { OrderAuctionStatusType } from "types/Order";

interface Props {
  status: OrderAuctionStatusType;
  className?: string;
}

const OrderAuctionStatusBadge: React.FC<Props> = ({
  className = "",
  status,
}) => {
  let name = getName(status);
  let addedClassName = getBadgeClassName(status);

  return <div className={`badge ${addedClassName} ${className}`}>{name}</div>;
};

function getBadgeClassName(status: string) {
  switch (status) {
    case "cancel":
    case "expired":
      return "badge-error";
    case "done":
      return "badge-success";
    case "wait":
    case "payment":
      return "badge-warning";
    default:
      return "";
  }
}

function getName(status: string) {
  switch (status) {
    case "wait":
      return "Ожидание";
    case "payment":
      return "Резерв";
    case "done":
      return "Оплачено";
    case "cancel":
      return "Перевод отклонен";
    case "expired":
      return "Истек";
    default:
      return "Неизвестно";
  }
}

export { OrderAuctionStatusBadge };
